import React, { useRef, useState, useEffect } from "react";
import html2canvas from "html2canvas";
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  backdropClasses,
} from "@mui/material";
import pg0 from "../../assets/Pg0.jpg";
import pg1 from "../../assets/Pg1.jpg";
import pg2 from "../../assets/Pg2.jpg";
import pg3 from "../../assets/Pg3.jpg";
import pg4 from "../../assets/Pg4.jpg";
import pg5 from "../../assets/Pg5.jpg";
import pg6 from "../../assets/Pg6.jpg";
import pg7 from "../../assets/Pg7.jpg";
import pg8 from "../../assets/Pg8.jpg";
import pg9 from "../../assets/Pg9.jpg";
import pg10 from "../../assets/Pg10.jpg";
import ProgressBar from "../../Components/ProgressBar";
import GraficTkg from "../../Components/GraficTkg";
import basicApi from "../../services/api";
import jsPDF from "jspdf";
import uploadFileToBlob from "../../utils/azureBlob";
import { useParams } from "react-router-dom";
import codificationParams from "../../utils/codificationParams";
import decodificationParams from "../../utils/decodificationParams";


function Dashboard() {
  const [rows, setRows] = useState([]);
  const [maturityTotal, setMaturityTotal] = useState("");
  const [linhas, setLinhas] = useState([]);
  const [printDesc, setPrintDesc] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoadedTable, setIsLoadedTable] = useState(false);
  const [tokenRDValido, setTokenRDValido] = useState("");
  const [nivelClient, setNivelClient] = useState("");

  const [blobList, setBlobList] = useState([]);

  const idQuizCodificado = localStorage.getItem("idQuiz");
  const idLeadCodificado = localStorage.getItem("idClient");
  const nameLeadCodificado = localStorage.getItem("nameClient");
  const emailLeadCodificado = localStorage.getItem("emailClient");
  const telLeadCodificado = localStorage.getItem("cellClient");
  const empresaLeadCodificado = localStorage.getItem("empresaClient");

  const idQuiz = decodificationParams(idQuizCodificado);
  const idLead = decodificationParams(idLeadCodificado);
  const nameLead = decodificationParams(nameLeadCodificado);
  const emailLead = decodificationParams(emailLeadCodificado);
  const telLead = decodificationParams(telLeadCodificado);
  const empresaLead = decodificationParams(empresaLeadCodificado);

  const dadosEventoRd = {
    name: nameLead,
    email: emailLead,
    personal_phone: telLead,
    company_name: empresaLead,
  };

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await basicApi.get(`/get-token`);
        const tokenRD = token.data.access_token;
        setTokenRDValido(tokenRD);
        basicApi.post(`/eventodashboard`, dadosEventoRd, {
          headers: {
            accept: "application/json",
            "content-type": "application/json",
            Authorization: `Bearer ${tokenRDValido}`,
          },
        });
      } catch (error) {}
    };
    getToken();
  }, []);

  useEffect(() => {
    const getNivel = async () => {
      try {
        const niveis = await basicApi.get(`/nivel`);
        const niveisArray = niveis.data;
        const result = await basicApi.get(`/percentualId/${idQuiz}`);
        const nivelAtual = result.data;
        const nivelColorData = {};
        const print = await basicApi.get(`/printId/${idQuiz}`);
        const resultPrintDesc = print.data;
        setPrintDesc(resultPrintDesc);

        nivelAtual.forEach((item) => {
          const { id_grupo_pergunta, nivel } = item;

          if (!nivelColorData[id_grupo_pergunta]) {
            nivelColorData[id_grupo_pergunta] = nivel;
          }
        });

        const formattedData = {};

        niveisArray.forEach((item) => {
          const {
            desc_grupo_pergunta,
            desc_nivel,
            desc_maturidade,
            id_nivel,
            id_grupo_pergunta,
            id_maturidade,
          } = item;

          if (!formattedData[desc_nivel]) {
            formattedData[desc_nivel] = [];
          }

          formattedData[desc_nivel].push({
            id_grupo_pergunta,
            desc_grupo_pergunta,
            desc_maturidade,
            id_maturidade,
            id_nivel,
            nivel: nivelColorData[id_nivel],
          });
        });
        setLinhas(formattedData);

        if (formattedData) {
          setIsLoadedTable(true);
        }
      } catch (error) {
        console.error("Erro ao buscar dados do quiz:", error);
      }
    };

    getNivel();
  }, [idQuiz]);

  useEffect(() => {
    const getResult = async () => {
      try {
        const result = await basicApi.get(`/percentualId/${idQuiz}`);
        const resultTotal = await basicApi.get(`/maturidadeId/${idQuiz}`);
        setRows(result.data);
        setNivelClient(resultTotal.data.map((d) => d.desc_nivel));
        const valueFinal = resultTotal.data.map((d) => d.maturidade_geral);
        const percentTotal = Math.round(valueFinal);
        setMaturityTotal(percentTotal);
        if (percentTotal) {
          setIsLoaded(true);
        }
      } catch (error) {
        console.error("Erro ao buscar dados do quiz:", error);
      }
    };

    getResult();
  }, [idQuiz]);

  const convertToCanvas = async (element) => {
    return new Promise((resolve, reject) => {
      html2canvas(element)
        .then((canvas) => {
          resolve(canvas);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const div1Ref = useRef(null);
  const div2Ref = useRef(null);
  const div3Ref = useRef(null);
  const div4Ref = useRef(null);
  const div5Ref = useRef(null);
  const div6Ref = useRef(null);
  const div7Ref = useRef(null);
  const div8Ref = useRef(null);
  const div9Ref = useRef(null);
  const div10Ref = useRef(null);


  useEffect(() => {
    if (isLoaded && isLoadedTable) {
      const handleConvert = async () => {
        const div1Element = div1Ref.current;
        const div2Element = div2Ref.current;
        const div3Element = div3Ref.current;
        const div4Element = div4Ref.current;
        const div5Element = div5Ref.current;
        const div6Element = div6Ref.current;
        const div7Element = div7Ref.current;
        const div8Element = div8Ref.current;
        const div9Element = div9Ref.current; 
        const div10Element = div10Ref.current;

        if (
          div1Element &&
          div2Element &&
          div3Element &&
          div4Element &&
          div5Element &&
          div6Element &&
          div7Element &&
          div8Element &&
          div9Element && 
          div10Element
        ) {
          try {
            const [
              canvas1,
              canvas2,
              canvas3,
              canvas4,
              canvas5,
              canvas6,
              canvas7,
              canvas8,
              canvas9,
              canvas10,
            ] = await Promise.all([
              convertToCanvas(div1Element),
              convertToCanvas(div2Element),
              convertToCanvas(div3Element),
              convertToCanvas(div4Element),
              convertToCanvas(div5Element),
              convertToCanvas(div6Element),
              convertToCanvas(div7Element),
              convertToCanvas(div8Element),
              convertToCanvas(div9Element), 
              convertToCanvas(div10Element),
            ]);

            const pdf = new jsPDF({
              orientation: "portrait", 
              unit: "mm",
              format: "a4",
            });
            
            const pdfWidth = 210;
            const pdfHeight = 297;

            const resizeImageToFitA4 = (canvas) => {
              const canvasWidth = canvas.width;
              const canvasHeight = canvas.height;
              const aspectRatio = canvasWidth / canvasHeight;

              let newWidth, newHeight;

              if (aspectRatio > 1) {
                newWidth = pdfWidth;
                newHeight = pdfWidth / aspectRatio;
              } else {
                newHeight = pdfHeight;
                newWidth = pdfHeight * aspectRatio;
              }

              return {
                width: newWidth,
                height: newHeight,
              };
            };
            const resizedDimensions1 = resizeImageToFitA4(canvas1);
            const resizedDimensions2 = resizeImageToFitA4(canvas2);
            const resizedDimensions3 = resizeImageToFitA4(canvas3);
            const resizedDimensions4 = resizeImageToFitA4(canvas4);
            const resizedDimensions5 = resizeImageToFitA4(canvas5);
            const resizedDimensions6 = resizeImageToFitA4(canvas6);
            const resizedDimensions7 = resizeImageToFitA4(canvas7);
            const resizedDimensions8 = resizeImageToFitA4(canvas8);
            const resizedDimensions9 = resizeImageToFitA4(canvas9); 
            const resizedDimensions10 = resizeImageToFitA4(canvas10);

            const imgData1 = canvas1.toDataURL("image/jpeg", 1.0);
            const imgData2 = canvas2.toDataURL("image/jpeg", 1.0);
            const imgData3 = canvas3.toDataURL("image/jpeg", 1.0);
            const imgData4 = canvas4.toDataURL("image/jpeg", 1.0);
            const imgData5 = canvas5.toDataURL("image/jpeg", 1.0);
            const imgData6 = canvas6.toDataURL("image/jpeg", 1.0);
            const imgData7 = canvas7.toDataURL("image/jpeg", 1.0);
            const imgData8 = canvas8.toDataURL("image/jpeg", 1.0);
            const imgData9 = canvas9.toDataURL("image/jpeg", 1.0);
            const imgData10 = canvas10.toDataURL("image/jpeg", 1.0);
          
            pdf.addImage(
              imgData1,
              "JPEG",
              0,
              0,
              resizedDimensions1.width,
              resizedDimensions1.height
            );
            pdf.addPage();
            pdf.addImage(
              imgData2,
              "JPEG",
              0,
              0,
              resizedDimensions2.width,
              resizedDimensions2.height
            );
            pdf.addPage();
            pdf.addImage(
              imgData3,
              "JPEG",
              0,
              0,
              resizedDimensions3.width,
              resizedDimensions3.height
            );
            pdf.addPage();
            pdf.addImage(
              imgData4,
              "JPEG",
              0,
              0,
              resizedDimensions4.width,
              resizedDimensions4.height
            );
            pdf.addPage();
            pdf.addImage(
              imgData5,
              "JPEG",
              0,
              0,
              resizedDimensions5.width,
              resizedDimensions5.height
            );
            pdf.addPage();
            pdf.addImage(
              imgData6,
              "JPEG",
              0,
              0,
              resizedDimensions6.width,
              resizedDimensions6.height
            );
            pdf.addPage();
            pdf.addImage(
              imgData7,
              "JPEG",
              0,
              0,
              resizedDimensions7.width,
              resizedDimensions7.height
            );
            pdf.addPage();
            pdf.addImage(
              imgData8,
              "JPEG",
              0,
              0,
              resizedDimensions8.width,
              resizedDimensions8.height
            );
            pdf.addPage();
            pdf.addImage(
              imgData9,
              "JPEG",
              0,
              0,
              resizedDimensions9.width,
              resizedDimensions9.height
            );
            pdf.addPage();
            pdf.addImage(
              imgData10,
              "JPEG",
              0,
              0,
              resizedDimensions10.width,
              resizedDimensions10.height
            );

          

            const pdfBytes = pdf.output("arraybuffer");

            const blobsInContainer = await uploadFileToBlob(pdfBytes);
            const link = blobsInContainer.blobUrl;

            setBlobList(link);
            try {
              const emailData = {
                anexo: link,
                id_cliente: idLead,
                nome_cliente: nameLead,
                empresa: empresaLead,
                email_cliente: emailLead,
                telefone: telLead,

              };

              await basicApi.post("/enviar-email", emailData);
              await basicApi.post("/enviar-email-interno", emailData);
            } catch (error) {
              console.error("Erro ao enviar o email:", error);
            }
          } catch (error) {
            console.error("Erro ao criar o PDF:", error);
          }
        }
      };

      handleConvert();
    }
  }, [emailLead, empresaLead, idLead, isLoaded, isLoadedTable, nameLead, telLead]);

  

  return (
 
    <Box
      component="main"
      className={`md:items-center transform-none`}
      sx={{
        backgroundColor: "#222C3F",
        width: "100%",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: "10px",
        overflowX: "visible",  
        overflowY: "visible",  
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexGrow: 1,
          width: "170mm",
          
        }}
        className="transform-none"
        ref={div1Ref}
      >
        <img
          src={pg0}
          alt="Pagina1"
          style={{ width: "100%", height: "100%" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "170mm", // Largura de uma página A4
          
        }}
        className="transform-none"
        ref={div2Ref}
      >
        <img
          src={pg1}
          alt="Pagina1"
          style={{ width: "100%", height: "100%" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          width: "170mm", // Largura de uma página A4
          
        }}
        className="transform-none"
        ref={div3Ref}
      >
        <img
          src={pg2}
          alt="Pagina2"
          style={{ width: "100%", height: "100%" }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          
          justifyContent: "center",
          width: "170mm", // Largura de uma página A4
          
        }}
        className="transform-none"
        ref={div4Ref}
      >
        <img
          src={pg3}
          alt="Pagina3"
          style={{ width: "100%", height: "100%" }}
        />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          
          justifyContent: "center",
          width: "170mm",
          
          position: "relative",
        }}
        className="transform-none"
        ref={div5Ref}
      >
        <img
          src={pg4}
          alt="Pagina4"
          style={{ width: "100%", height: "100%" }}
        />
        
        <div
          style={{
            position: "absolute",
            top: "143px",
            left: "78px",
            zIndex: 1,
            display: "flex",
            
          }}
        >
          <span className="font-nexa-bold top-48 font-bold text-center text-xl text-button-blue-initial ">
            Parecer sobre suas respostas
          </span>
        </div>
        <div
          style={{
            position: "absolute",
            top: "212px",
            left: "78px",
            zIndex: 1,
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <div style={{ flex: 1 }}>
            <p className="font-nexa-bold font-normal text-sm text-black text-justify">
              Após a análise de suas respostas do questionário, sua organização
              foi considerada pertencente ao{" "}
              <span className="font-bold">{nivelClient}</span> de Maturidade de
              Dados na classificação geral que é composta pelos pilares de
              Governança, Qualidade, Segurança, Arquitetura e Análises de dados.
            </p>
          </div>
          <GraficTkg  className="" porcentagem={maturityTotal} />
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          width: "170mm",
          
          position: "relative",
        }}
        className="transform-none"
        ref={div6Ref}
      >
        <img
          src={pg5}
          alt="Pagina5"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            top: "8rem",
            left: "78px",
            zIndex: 1,
            display: "flex",
            alignItems: "flex-start",
            width: "32rem",
          }}
        >
          <p className="font-nexa-bold font-normal text-sm text-black text-justify">
            Veja a Maturidade atingida por sua empresa em cada um deles a
            seguir:
          </p>
        </div>
        <div
          style={{
            position: "absolute",
            top: "12rem",
            left: "50px",
            zIndex: 1,
            display: "flex",
            alignItems: "flex-start",
            width: "32rem",
          }}
        >
          <Box className="w-[32rem] h-80 ml-7 relative mx-2">
            <div className="bg-button-blue-initial absolute top-0 left-0 right-0 rounded-t-xl p-5 flex flex-wrap justify-center w-full">
              <span className="font-bold font-nexa-bold text-white text-md">
                PILARES
              </span>
            </div>

            <div className="bg-title-blue mt-14 relative flex flex-col justify-start items-start rounded-b-lg p-2 text-sm ">
              {rows.map((row, id_grupo_pergunta) => (
                <div
                  key={`group-question-${id_grupo_pergunta}`}
                  className="flex items-center mb-3 w-full"
                >
                  <div className="flex items-left  mr-9 break-words" style={{minWidth:"125px"}}>
                      <span
                        className="font-bold font-nexa-bold text-white ml-4 mt-1 text-[15px]" style={{maxWidth:"120px"}}
                      >
                        {row.desc_grupo_pergunta}
                      </span>
                  </div>

                  <div className="flex items-center justify-center mr-6" >
                    <ProgressBar percentage={row.percentual_grupo} />
                  </div>
                </div>
              ))}
            </div>
          </Box>
        </div>
        <div
          style={{
            position: "absolute",
            top: "40rem",
            left: "78px",
            zIndex: 1,
            display: "flex",
            alignItems: "flex-start",
            width: "32rem",
          }}
        >
          <p className="font-nexa-bold font-normal text-sm text-black text-justify">
            A Matriz de Maturidade de Dados, apresentada abaixo, permite
            identificar como estão distribuídos os níveis em cada um dos
            pilares. Saber onde sua organização se encontra e quais os níveis
            podem ser atingidos, possibilita traçar a estratégia para aproveitar
            o melhor dos dados nas decisões do dia a dia.
          </p>
        </div>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          
          justifyContent: "center",
          width: "170mm",
          
          position: "relative",
        }}
        className="transform-none"
        ref={div7Ref}
      >
        <img
          src={pg6}
          alt="Pagina6"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            top: "5rem",
            left: "7px",
            zIndex: 1,
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ display: "flex", height: "100%" }}>
            <div className="= flex flex-col w-auto">
              <TableContainer
                component={Paper}
                style={{ overflow: "hidden", width: "100%" }}
              >
                <Table>
                  <TableHead className="bg-title-blue rounded-b-2xl">
                    <TableRow>
                      <TableCell
                        className={"border-2 w-2/12"}
                        sx={{lineHeight:1}}
                        style={{ textAlign: "center" }}
                      >
                        <span className="font-nexa-bold font-normal text-[8.5px] text-white">
                          NÍVEL DE MATURIDADE
                        </span>
                      </TableCell>
                      {Object.keys(linhas).map((nivel) => (
                        <TableCell
                          sx={{ lineHeight: 1 }}
                          style={{ textAlign: "center" }}
                          className={"border-2"}
                          key={nivel}
                        >
                          <span className="font-nexa-bold font-normal text-[8.5px] text-white">
                            {nivel}
                          </span>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody className="font-nexa-bold font-normal text-white">
                    {linhas[Object.keys(linhas)[0]] &&
                      Object.keys(linhas[Object.keys(linhas)[0]]).map(
                        (grupoIndex) => (
                        <TableRow key={grupoIndex}>
                            <TableCell
                              sx={{ lineHeight: 1 }}
                              style={{ textAlign: "center" }}
                              className="bg-title-blue border-2 "
                            >
                              <span className="font-black font-nexa font-normal text-[8.5px] text-white">
                                {
                                  linhas[Object.keys(linhas)[0]][grupoIndex]
                                    .desc_grupo_pergunta
                                }
                              </span>
                            </TableCell>
                            {Object.keys(linhas).map((nivel) => {
                              const grupo = linhas[nivel][grupoIndex];
                              const matchingPrintItem = printDesc.find(
                                (item) =>
                                  item.id_maturidade === grupo.id_maturidade
                              );
                              const shouldHighlight = !!matchingPrintItem;

                              return (
                                <TableCell
                                  sx={{ padding: 1, lineHeight: 1 }}
                                  key={nivel}
                                  className={`border-2  ${
                                    shouldHighlight
                                      ? "bg-title-blue text-white"
                                      : "bg-white text-title-blue"
                                  }`}
                                >
                                  <span
                                    className={`font-black font-nexa font-normal text-[8.5px]  ${
                                      shouldHighlight
                                        ? "bg-title-blue text-white"
                                        : "bg-white text-title-blue"
                                    }`}
                                  >
                                    {grupo.desc_maturidade || ""}
                                  </span>
                                </TableCell>
                              );
                            })}
                          </TableRow>
                        )
                      )}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          </Box>
        </div>
      </Box>
     <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          
          justifyContent: "center",
          width: "170mm",
          
          position: "relative",
        }}
        className="transform-none"
        ref={div8Ref}
      >
        <img
          src={pg7}
          alt="Pagina6"
          style={{ width: "100%", height: "100%" }}
        />
        
        <div
          style={{
            position: "absolute",
            top: "6rem",
            left: "78px",
            zIndex: 1,
            display: "flex",
            
          }}
        >
          
          <span className="font-nexa-bold top-48 font-bold text-center text-xl text-button-blue-initial ">
            Considerações
          </span>
        </div>
        <div
          style={{
            position: "absolute",
            top: "9rem",
            left: "78px",
            zIndex: 1,
            display: "flex",
            alignItems: "flex-start",
            width: "32rem"
          }}
        >
          <p className="font-nexa-bold font-normal text-sm text-black text-justify leading-8">
            Se sua organização não atingiu o nível 5 ainda, há oportunidades
            para melhorarmos os processos de dados e garantir que as definições
            estabelecidas sejam aderentes a uma política data-driven. Se suas
            respostas resultaram no nível máximo, parabéns! Ainda assim, nós
            podemos ajudá-los a ir além com nossa equipe de modelagem de dados e
            Inteligência Artificial! Porquê o importante é extrairmos sempre o
            máximo de informações dos dados e zelarmos pelas qualidade e
            segurança. Lembrando que dados são os portadores de informações que
            se bem utilizados agregam e auxiliam nas tomadas de decisões e que
            quanto mais data-driven, mais sua organização tem agilidade para
            perceber as movimentaçoes internas e responder melhor ao mercado.
            Nós da Taking, estamos à disposição para juntos alcançarmos os niveis 
            mais altos de Maturidade de Dados no seu negócio.
          </p>
          
        </div>
          
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          
          justifyContent: "center",
          width: "170mm",
          
          position: "relative",
        }}
        className="transform-none"
        ref={div9Ref}
      >
        <img
          src={pg8}
          alt="Pagina7"
          style={{ width: "100%", height: "100%" }}
        />
         
        
        
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          
          justifyContent: "center",
          width: "170mm",
          
          position: "relative",
        }}
        className="transform-none"
        ref={div10Ref}
      >
        <img
          src={pg9}
          alt="Pagina8"
          style={{ width: "100%", height: "100%" }}
        />
        <div
          style={{
            position: "absolute",
            top: "143px",
            left: "163px",
            zIndex: 1,
            display: "flex",
            
          }}
        ></div>
      </Box>
      
    </Box>
  );
}

export default Dashboard;
