import React, { useRef } from "react";
import classNames from "classnames";
import InputMask from "react-input-mask";

const Input = ({ type = "text", variant = "primary", ...props }) => {
  const myInputRef = useRef(null);

  const handleClick = () => {
    if (myInputRef.current) {
      myInputRef.current.focus(); 
    }
  };

  let bgColor = "text-black";

  if (variant === "primary")
    bgColor =
      "w-80 mb-2 text-sm mb-2 ml-2 mt-2 mr-2 font-nexa-bold text-black dark:text-black border-4 border-white-200 border-transparent border-5 outline outline-0 hover:bg-white rounded-lg";

  return (
    <div className={classNames("relative flex")}>
      {type === "number" ? (
        <InputMask
          ref={myInputRef}
          className={classNames("px-5 py-2 text-lg w-full", bgColor)}
          mask="(99) 99999-9999"
          maskChar="_"
          placeholder="Telefone:" 
          {...props}
        />
      ) : (
        <>
          <input
            ref={myInputRef}
            className={classNames("px-5 py-2 my-3 text-lg w-full", bgColor)}
            type={type}
            onChange={handleClick}
            placeholder=""
            {...props}
          />
        </>
      )}
    </div>
  );
};

export default Input;
