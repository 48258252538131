import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Checkbox, Link } from "@mui/material";
import Input from "../../Components/Input";
import Label from "../../Components/Label";
import ErrorModal from "../../Components/Modal";
import Button from "../../Components/Button";
import logoTaking from "../../assets/GRUPO_TAKING_1.png";
import Footer from "../../Components/Footer";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import basicApi from "../../services/api";
import LoadingPage from "../../Components/LoadingPage";
import { useParams } from "react-router-dom";
import codificationParams from "../../utils/codificationParams";
import decodificationParams from "../../utils/decodificationParams";

import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function RegisterPage() {
  const navigate = useNavigate();

  const [apiError, setApiError] = useState("");
  const [open, setOpen] = useState(false);
  const [apiTitle, setApiTitle] = useState("");
  const [tokenRDValido, setTokenRDValido] = useState("");
  const handleClose = () => setOpen(false);
  const { _id } = useParams();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await basicApi.get(`/get-token`);
        const tokenRD = token.data.access_token;
        setTokenRDValido(tokenRD);
      } catch (error) { }
    };
    getToken();
  }, []);

  const handleSubmit = async (values) => {
    const dados = {

      nome_cliente: values.nome_cliente,
      empresa: values.empresa,
      email_cliente: values.email_cliente,
      telefone: values.telefone,
      comunica: values.comunica,
      data_cadastro: new Date().toISOString(),
    };

    const dadosRd = {
      name: values.nome_cliente,
      email: values.email_cliente,
      personal_phone: values.telefone,
    };

    const dadosEventoRd = {
      name: values.nome_cliente,
      email: values.email_cliente,
      personal_phone: values.telefone,
      company_name: values.empresa,
    };
    const idLeadCodificado = localStorage.getItem("idClient");
    const decodedId = decodificationParams(idLeadCodificado);

    basicApi
      .patch(`/updatedcliente/${decodedId}`, dados)
      .then((response) => {
        const idLead = response.data.map((d) => d.id_cliente);
        const nameLead = response.data.map((d) => d.nome_cliente);
        const emailLead = response.data.map((d) => d.email_cliente);
        const telLead = response.data.map((d) => d.telefone);
        const empresaLead = response.data.map((d) => d.empresa);
        const idLeadCodificado = codificationParams(idLead);
        const nameLeadCodificado = codificationParams(nameLead);
        const emailLeadCodificado = codificationParams(emailLead);
        const telLeadCodificado = codificationParams(telLead);
        const empresaLeadCodificado = codificationParams(empresaLead);
        localStorage.setItem("idClient", idLeadCodificado);
        localStorage.setItem("nameClient", nameLeadCodificado);
        localStorage.setItem("emailClient", emailLeadCodificado);
        localStorage.setItem("cellClient", telLeadCodificado);
        localStorage.setItem("empresaClient", empresaLeadCodificado);
        if (response.status === 200) {
          setTimeout(() => {
            navigate("/dashboard/" + _id);
          }, 3000);
          setOpen(true);
          setApiTitle("Carregando...Aguarde!");

        }
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          setOpen(true);
          setApiTitle("Servidor fora de serviço.");
          setApiError("Tente Novamente mais tarde!");
        }
        if (error.response.status === 400) {
          setOpen(true);
          setApiTitle("Dados Inválidos");
          setApiError("Verifique as informações e tente novamente!");
        }
      });

    await basicApi
      .post("/clienterd", dadosRd, {
        headers: {
          Authorization: `Bearer ${tokenRDValido}`,
        },
      })
      .then((response) => {
        if (response.status === 200) {
          basicApi.post(`/eventoquiz`, dadosEventoRd, {
            headers: {
              accept: "application/json",
              "content-type": "application/json",
              Authorization: `Bearer ${tokenRDValido}`,
            },
          });

        }
      });
  };


  const publicDomains = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'outlook.com',
    'live.com',
    'icloud.com'
  ];

  const isCorporateEmail = (email) => {
    const domain = email.split('@')[1];
    return !publicDomains.includes(domain);
  };

  const validationSchema = Yup.object().shape({
    nome_cliente: Yup.string().required('Nome é obrigatório'),
    empresa: Yup.string().required('Empresa é obrigatória'),
    email_cliente: Yup.string()
      .email('E-mail inválido')
      .required('E-mail é obrigatório')
      .test('is-corporate-email', 'E-mail inválido, utilize o endereço corporativo', value => isCorporateEmail(value)),
    telefone: Yup.string().required('Telefone é obrigatório'),
  });
  const fontSmall = isSmallScreen ? "text-[12px]" : "";

  return (
    <Box sx={{ display: "flex", flexDirection: "column", minHeight: "100vh" }} className="transform-none">
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          overflow: "auto",
        }}
      >

        <div className="w-full relative bg-[#13283D]">
          <img
            src={logoTaking}
            alt="Logo Taking"
            className="p-4 content-start max-w-[130px] 2xl:max-w-[200px] sm:float-none"
          />
        </div>
        {apiError ? (
          <ErrorModal
            open={open}
            onClose={handleClose}
            title={apiTitle}
            errorMessage={apiError}
          />
        ) : (
          <LoadingPage open={open} onClose={handleClose} title={apiTitle} />
        )}
        <Formik
          initialValues={{
            nome_cliente: "",
            empresa: "",
            email_cliente: "",
            telefone: "",
            comunica: false,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {(props) => (
            <Form onSubmit={props.handleSubmit} className="flex-grow">
              <div className="flex justify-center items-center py-8">
                <div className="bg-title-blue w-full max-w-2xl mb-9 relative rounded-lg drop-shadow-2xl p-8">
                  <span className={`${fontSmall} text-lg md:text-xl font-nexa-bold text-white font-bold text-center mb-4 block`}>
                    RECEBA O RESULTADO DO SEU TESTE GRATUITAMENTE
                  </span>
                  <p className={`${fontSmall} text-base md:text-lg font-nexa-bold text-white text-center mb-4`}>
                    Informe seus dados para que possamos enviar seu teste.
                  </p>
                  <Field
                    as={Input}
                    name="nome_cliente"
                    autoComplete="username"
                    placeholder="Nome:"
                    id="nome_cliente"
                    type="text"
                  />
                  {props.errors.nome_cliente && (
                    <span className="mb-2 text-xs font-bold text-center text-red-600 block">
                      {props.touched.nome_cliente && props.errors.nome_cliente}
                    </span>
                  )}
                  <Field
                    as={Input}
                    name="empresa"
                    autoComplete="business"
                    placeholder="Empresa:"
                    id="empresa"
                    type="text"
                  />
                  {props.errors.empresa && (
                    <span className="mb-2 text-xs font-bold text-center text-red-600 block">
                      {props.touched.empresa && props.errors.empresa}
                    </span>
                  )}
                  <Field
                    as={Input}
                    name="email_cliente"
                    autoComplete="email"
                    placeholder="E-mail:"
                    id="email_cliente"
                    type="text"
                  />
                  {props.errors.email_cliente && (
                    <span className="mb-2 text-xs font-bold text-center text-red-600 block">
                      {props.touched.email_cliente && props.errors.email_cliente}
                    </span>
                  )}
                  <Field
                    as={Input}
                    name="telefone"
                    autoComplete="telefone"
                    placeholder="Telefone:"
                    id="telefone"
                    type="number"
                  />
                  {props.errors.telefone && (
                    <span className="mb-2 text-xs font-bold text-center text-red-600 block">
                      {props.touched.telefone && props.errors.telefone}
                    </span>
                  )}
                  <div className="relative flex items-center">
                    <Field name="comunica">
                      {({ field }) => (
                        <Checkbox
                          {...field}
                          id="comunica"
                          checked={field.value === true}
                          style={{
                            color: field.value ? "primary" : "white",
                            "&.Mui-checked": {
                              color: "primary",
                            },
                          }}
                        />
                      )}
                    </Field>

                    <Label className="font-nexa-bold text-white">
                      Eu concordo em receber comunicações.
                    </Label>
                  </div>
                  <div className="relative flex items-center ml-9">
                    <Label className="font-nexa-bold text-white underline-offset-1 mt-2 ml-0">
                      <span className="ml-0">
                        Ao informar meus dados, estou ciente
                        <br />
                        das diretrizes da{" "}
                        <Link
                          href="https://taking.com.br/politica-de-privacidade/"
                          target="_blank"
                        >
                          Política de Privacidade.
                        </Link>
                      </span>
                    </Label>

                  </div>
                  <div className="flex justify-center items-center py-8">
                    <Button
                      color="success"
                      type="submit"
                      disabled={props.isSubmitting}
                      onClick={() => handleSubmit}
                    >
                      Enviar
                    </Button>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Box>
      <Footer sx={{ mt: "auto" }} />
    </Box>
  );
}
export default RegisterPage;
