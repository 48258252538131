import React, { useEffect } from "react";
import Routes from './Routes';

function App() {

  useEffect(() => {
    const userLanguage = navigator.language || navigator.userLanguage;

    document.documentElement.lang = userLanguage;

    document.documentElement.setAttribute('translate', 'no');
  }, []);
  return (
    <Routes />
  );

}
export default App;
