import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CircularProgress from "@mui/material/CircularProgress";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #222C3F",
  boxShadow: 24,
  p: 4,
  borderRadius: "10px",
};

const LoadingPage = ({ open, onClose, title }) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          {title}
        </Typography>
        <Box className="pt-2 flex justify-center items-center" sx={{ display: "flex" }}>
          <CircularProgress />
        </Box>
      </Box>
    </Modal>
  );
};

export default LoadingPage;
