import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import Quiz from "./Pages/Quiz/Quiz";
import Dashboard from "./Pages/Dashboard/Dashboard";
import RegisterPage from "./Pages/LandingPage/RegisterPage";

const PageRoutes = () => {
  return (
    <Router>
      <Routes>
         
        <Route exact path="/" element={<Quiz />} />
        <Route exact path="/quiz" element={<Quiz />} />
        <Route exact path="/register/:_id" element={<RegisterPage />} /> 
        <Route exact path="/dashboard/:_id" element={<Dashboard/>} />

      </Routes>
    </Router>
  );
};

export default PageRoutes;
