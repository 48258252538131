import React from "react";
import empiricoImage from "../assets/Empírico.png";
import inicialImage from "../assets/Inicial.png";
import definidoImage from "../assets/Definido.png";
import otimizadoImage from "../assets/Otimizado.png";
import exponencialImage from "../assets/Exponencial.png";
import { Box } from "@mui/material";
const GraficTkg = ({ porcentagem }) => {
  let categoriaImagem;
  let categoriaRelatorio;

  if (porcentagem <= 30) {
    categoriaImagem = "empirico";
    categoriaRelatorio = "A maneira de trabalhar com os dados é baseada nas experiências vividas no dia-a-dia.";
  } else if (porcentagem <= 50) {
    categoriaImagem = "inicial";
    categoriaRelatorio =
      "A importância dos dados é reconhecida; os dados são percebidos como inadequados e iniciativas são iniciadas para que haja segurança e arquitetura de dados.";
  } else if (porcentagem <= 70) {
    categoriaImagem = "definido";
    categoriaRelatorio =
      "Há políticas para o armazenamento e uso dos dados, procedimentos, processos e equipes especializadas. Tecnologias avançadas são usadas para monitorar, proteger e gerenciar os dados.";
  } else if (porcentagem <= 90) {
    categoriaImagem = "otimizado";
    categoriaRelatorio =
      "A governança de dados já é parte da cultura da empresa. Os dados são confiáveis para tomadas de decisão, as medidas de segurança avançadas foram implementadas, a arquitetura de dados é prioridade e a análise de dados é estratégica.";
  } else {
    categoriaImagem = "exponencial";
    categoriaRelatorio =
      "A governança de dados é fundamental. O gerenciamento dos dados é eficaz e eles são compartilhados com eficiência. Todas as medidas de segurança necessárias foram implementadas. A análise dados é fundamental para auxiliar nas tomadas de decisões estratégicas.";
  }

  const images = {
    empirico: empiricoImage,
    inicial: inicialImage,
    definido: definidoImage,
    otimizado: otimizadoImage,
    exponencial: exponencialImage,
  };

  return (
    <>
      <Box className="w-80 h-80 ml-7 relative mt-0.5">      
        <div className="bg-button-blue-initial absolute justify-center flex flex-wrap w-64 items-center pb-5 -top-1 left-0 right-0 rounded-t-xl p-2 text-sm mx-2.5">
          <span className="font-bold font-nexa-bold text-white">
            CLASSIFICAÇÃO GERAL
          </span>
        </div>
        <div className="bg-title-blue relative flex flex-col justify-center items-center -bottom-8 left-0 right-0 rounded-b-lg p-10 w-64 mx-2.5">
          <div className="flex flex-col items-center">
            <img
              src={images[categoriaImagem]}
              alt={categoriaImagem}
              className="max-w-full max-h-full"
            />
            <span className="text-center font-normal font-nexa-bold text-white mt-2 text-xl">{porcentagem}%</span>
            <br></br>
            <span className="text-left font-normal font-nexa-bold text-white mt-2 text-sm text-justify">
              {categoriaRelatorio}
            </span>
          </div>{" "}
        </div>
      </Box>

    </>
  );
};

export default GraficTkg;
