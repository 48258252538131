import classNames from "classnames";
import React from "react";
import { Box } from "@mui/material";
import RedesSociais from "../assets/icon-blue.png";
import IconFacebook from "../assets/icon-Facebook.png";
import IconInstagram from "../assets/icon-Instagram.png";
import IconLinkedin from "../assets/icon-Linkedin.png";
import IconYoutube from "../assets/icon-Youtube.png";

import Icon from "../Components/Icon";

const Footer = ({ children, asChild, className }) => {
  return (
    <>
    
          
    <Box className="relative bg-title-blue w-full text-center">
  <span className="text-white  drop-shadow-xl py-8 font-nexa-bold ml-2 md:text-lg block">
    Nos siga nas redes sociais e fique por dentro de todas as novidades!
  </span>

  <div className="flex justify-center items-center mt-2 pb-8">
    <a href="https://www.facebook.com/takingconsultoria/" target="_blank" rel="noopener noreferrer">
      <Icon src={IconFacebook} alt="Facebook" className="w-11 h-11 mx-2" />
    </a>
    <a href="https://instagram.com/grupo.taking?igshid=MzRlODBiNWFlZA==" target="_blank" rel="noopener noreferrer">
      <Icon src={IconInstagram} alt="Instagram" className="w-11 h-11 mx-2" />
    </a>
    <a href="https://www.linkedin.com/company/taking/mycompany/" target="_blank" rel="noopener noreferrer">
      <Icon src={IconLinkedin} alt="Linkedin" className="w-11 h-11 mx-2" />
    </a>
    <a href="https://www.youtube.com/@taking6310" target="_blank" rel="noopener noreferrer">
      <Icon src={IconYoutube} alt="Youtube" className="w-11 h-11 mx-2" />
    </a>
  </div>
</Box>



        {children}
    </>
  );
};

export default Footer;
