import React from 'react';
import MobileStepper from '@mui/material/MobileStepper';

const Steeper = ({ activeStep, handleStepChange }) => {


  return (
    <div className='relative flex justify-center'>
      <MobileStepper
        variant="dots"
        steps={5}
        position="static"
        activeStep={activeStep}
        sx={{
          position:'relative',
          alignItems: 'center',
          justifyContent: 'center',
          maxWidth: '100%',
          height: 40,
          flexGrow: 1,
          '& .MuiMobileStepper-dot': {
            width: 50,
            height: 10,
            borderRadius: 2,
            margin: '0 2px',
            backgroundColor: 'rgba(0, 0, 0, 0.1)',
            transition: 'background-color 0.4s ease',
          },
          '& .MuiMobileStepper-dotActive': {
            backgroundColor: '#064AB7',
          },
        }}
      />
      
    </div>
  );
}

export default Steeper;