import { BlobServiceClient } from '@azure/storage-blob';

const sasToken = process.env.REACT_APP_SAS_TOKEN;
const containerName = process.env.REACT_APP_CONTAINER_NAME;
const storageAccountName = process.env.REACT_APP_STORAGE_ACCOUNT_NAME

export const isStorageConfigured = () => {
  return !(!storageAccountName || !sasToken);
};

  const createBlobInContainer = async (containerClient, pdfBytes) => {
    const uniqueIdentifier = Date.now().toString();
  
    const blobName = `resultado_maturidade_${uniqueIdentifier}.pdf`;
  
    const blobClient = containerClient.getBlockBlobClient(blobName);
  
    const options = { blobHTTPHeaders: { blobContentType: 'application/pdf' } };
  
    await blobClient.uploadData(pdfBytes, options);
    await blobClient.setMetadata({ UserName: 'samii' });
  
    return blobClient.url; 
  };
  

  const uploadFileToBlob = async (pdfBytes) => {
    if (!pdfBytes) return [];
  
    const blobService = new BlobServiceClient(
      `https://${storageAccountName}.blob.core.windows.net?${sasToken}`
    );
  
    const containerClient = blobService.getContainerClient(containerName);
  
    const blobUrl = await createBlobInContainer(containerClient, pdfBytes);
    return {
      blobUrl, 

    };
  };
  

export default uploadFileToBlob;
