import React from "react";
import { Slot } from "@radix-ui/react-slot";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

const Button = ({
  children,
  asChild,
  disabled,
  color = "primary",
  className,
  onClick,
  ...props
}) => {
  const Component = asChild ? Slot : "button";
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"))
  const isBigScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const fontSmall = isSmallScreen ? "text-[9px]" : ""; 
  const fontBig = isBigScreen ? "text-2xl" : ""; 
  return (
    <Component
      className={`
        focus-ring-2 flex transform self-center transition hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none 
        ${
          color === "primary"
            ? `rounded-lg items-center bg-transparent outline outline-2 outline-offset-2 px-1 py-1 mt-6 font-semibold text-white ring-white ${fontSmall} md:text-md lg:text-lg xl:text-lg 2xl:text-xl hover:shadow-white shadow-md`
            : ""
        }
      
        ${
          color === "success"
            ? "rounded-md items-center font-nexa-bold bg-button-blue-initial px-11 py-1  text-white hover:shadow-white shadow-md"
            : ""
        }
         
        ${className ? className : ""}
      `}
      onClick={onClick}
      {...props}
    >
      {color === "success" ? (
        <>{children}</>
      ) : (
        <>
          <ArrowForwardIosIcon sx={{ fontSize: 15, marginRight: isSmallScreen ? 3 : 10 }} />
          {children}
          <ArrowBackIosIcon sx={{ fontSize: 15, marginLeft: isSmallScreen ? 3 : 10 }} />
        </>
      )}
    </Component>
  );
};

export default Button;
