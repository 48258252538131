import React from "react";
import { Slot } from "@radix-ui/react-slot";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
const ButtonNavbar = ({
  children,
  asChild,
  disabled,
  color = "primary",
  className,
  onClick,
  ...props
}) => {
  const Component = asChild ? Slot : "button";

  return (
    <Component
    className={`
    focus-ring-2 flex transform self-center transition hover:-translate-y-1 motion-reduce:transition-none motion-reduce:hover:transform-none
    ${
      color === "primary" || color === "secundary"
        ? "rounded-lg items-center font-nexa-light bg-button-blue-initial px-11 py-1 md:text-md lg:text-lg xl:text-lg 2xl:text-xl text-white hover:shadow-white shadow-md"
        : ""
    }
    ${className ? className : ""}
  `}
      onClick={onClick}
      {...props}
    >
      {color === "primary" ? (
        <>

          {children}
          <ArrowForwardIosIcon sx={{ fontSize: 15, margin: 1 }} />

        </>
      ) : (
        <>
          {children}
          <ArrowBackIosIcon sx={{ fontSize: 15, margin: 1 }} />
        </>
      )}
    </Component>
  );
};

export default ButtonNavbar;
