import React from "react";
import LinearProgress from "@mui/material/LinearProgress";

const ProgressBar = ({ percentage }) => {
  return (
    <div >
      <div
        style={{ textAlign: "right", fontWeight: "bold", color: "white", marginBottom: 2 }}
      >{`${percentage}%`}</div>

      <LinearProgress
        variant="determinate"
        value={percentage}
        sx={{
          width: "auto",
          height: 16,
          borderRadius: 5,
          marginBottom: 2,
          width: '20rem',
          "& .MuiLinearProgress-bar": {
            borderRadius: 5,
          },
        }}
      />
    </div>
  );
};

export default ProgressBar;
