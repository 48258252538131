import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { Box, Radio } from "@mui/material";
import Steeper from "../../Components/Steeper";
import ButtonNavbar from "../../Components/ButtonNavbar";
import basicApi from "../../services/api";
import codificationParams from "../../utils/codificationParams";
import decodificationParams from "../../utils/decodificationParams";

function Quiz() {
  const navigate = useNavigate();

  const { _id } = useParams();
  const encodedId = codificationParams(_id);
  const [topics, setTopics] = useState([]);

  const [idQuiz, setIdQuiz] = useState("");


  const steps = ["1/5", "2/5", "3/5", "4/5", "5/5"];
  const [currentGroup, setCurrentGroup] = useState(0);
  const [groups, setGroups] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedAnswers, setSelectedAnswers] = useState(
    topics.map((topic) => Array(topic.questions.length).fill(null))
  );
  const [tokenRDValido, setTokenRDValido] = useState("");

  useEffect(() => {
    const getToken = async () => {
      try {
        const token = await basicApi.get(`/get-token`);
        const tokenRD = token.data.access_token;
        setTokenRDValido(tokenRD);
        const response = await basicApi.post("/newcliente", dados);
        const idLead = response.data.map((d) => d.id_cliente);
        const idLeadCodificado = codificationParams(idLead);
        localStorage.setItem("idClient", idLeadCodificado);
        const idLeadDecodificado = decodificationParams(idLeadCodificado);
        if (idLeadDecodificado) {
          const quizResponse = await basicApi.post("/newquiz", {
            id_cliente: idLeadDecodificado,
            soma_resultado_quiz: 0,
            data_cadastro: new Date().toISOString(),
          });
          const quizId = quizResponse.data.map((d) => d.id_quiz)

          localStorage.setItem("idQuiz", codificationParams(quizId));
          const idQuizCodificado = localStorage.getItem("idQuiz");

          const idQuiz = decodificationParams(idQuizCodificado);
          setIdQuiz(idQuiz);

        }
      } catch (error) { }
    };
    getToken();
  }, []);

  const dados = {
    nome_cliente: 'novoLead',
    empresa: 'novaEmpresa',
    email_cliente: 'novoEmail',
    telefone: 'novoTelefone',
    comunica: true,
    data_cadastro: new Date().toISOString(),
  };

  useEffect(() => {
    const getQuiz = async () => {
      try {
        const result = await basicApi.get("/questionario");
        const newGroups = [];

        let currentGroup = null;
        let currentQuestions = [];
        let currentGroupId = null;

        result.data.forEach((question) => {
          if (currentGroupId !== question.id_grupo_pergunta) {
            if (currentGroup !== null) {
              newGroups.push({
                group: currentGroup,
                questions: currentQuestions,
                id_grupo_pergunta: currentGroupId,
              });
            }
            currentGroupId = question.id_grupo_pergunta;
            currentGroup = question.desc_grupo_pergunta;
            currentQuestions = [];
          }

          currentQuestions.push({
            id_pergunta: question.id_pergunta,
            desc_pergunta: question.desc_pergunta,
            valor_pergunta: null,
          });
        });

        if (currentGroupId !== null) {
          newGroups.push({
            group: currentGroup,
            questions: currentQuestions,
            id_grupo_pergunta: currentGroupId,
          });
        }

        setGroups(newGroups);

        setCurrentStep(0);
      } catch (error) {
        console.error("Erro ao buscar dados do quiz:", error);
      }
    };

    getQuiz();
  }, []);

  const getAnswers = async (groupIndex) => {
    try {
      const { id_grupo_pergunta, questions } = groups[groupIndex];
      const answeredQuestions = questions.filter(
        (question) => question.valor_pergunta !== null
      );

      for (const question of answeredQuestions) {
        const id_pergunta = question.id_pergunta;
        const valor_resposta = question.valor_pergunta;

        const res = await basicApi.post("/newrespostas", {
          id_quiz: idQuiz,
          id_grupo_pergunta,
          id_pergunta,
          valor_resposta,
          data_cadastro: new Date().toISOString(),
        });
      }
    } catch (error) {
      console.error("Erro ao enviar respostas:", error);
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep(activeStep - 1);
      setCurrentGroup(currentGroup - 1);
      setCurrentStep(currentStep - 1);
      setSelectedAnswers((prevSelectedAnswers) => {
        const updatedSelectedAnswers = [...prevSelectedAnswers];
        updatedSelectedAnswers[currentStep - 1] = [];
        return updatedSelectedAnswers;
      });
    }
  };

  const handleAnswerChange = (groupIndex, questionIndex, answerValue) => {
    setGroups((prevGroups) => {
      const updatedGroups = [...prevGroups];
      updatedGroups[groupIndex].questions[questionIndex].valor_pergunta =
        answerValue;
      return updatedGroups;
    });
  };

  const areAllQuestionsAnswered = (groupIndex) => {
    const currentQuestions = groups[groupIndex]?.questions;
    return currentQuestions.every(
      (question) => question.valor_pergunta !== null
    );
  };

  return (
    <Box sx={{ display: "flex" }} className="transform-none">
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          height: "100vh",
          overflow: "auto",
        }}
      >
        <div className="relative flex flex-col items-center justify-center h-40 2xl:h-56 bg-title-blue w-full text-white text-center font-nexa-bold">
          <span className="w-full font-bold md:text-md lg:text-lg xl:text-xl 2xl:text-2xl overflow-wrap">
            TESTE DE MATURIDADE DE DADOS
          </span>
          <span className="w-full md:text-sm lg:text-md xl:text-lg 2xl:text-xl mt-2 overflow-wrap">
            Em uma <span className="font-bold">escala de 1 a 5</span>, responda
            as perguntas abaixo, sendo 1 que <br></br> não possui nada e 5 que
            está pensando em evoluir.
          </span>
        </div>

        <br></br>
        <div className="flex justify-center mb-2">
          <div>
            {groups[currentStep] && (
              <div>
                <span className="p-30 font-nexa-bold font-bold md:text-lg p-20 lg:text-xl xl:text-xl 2xl:text-xl text-title-blue">
                  {groups[currentStep].group}
                </span>
              </div>
            )}
            <div className="text-center">
              <Steeper activeStep={activeStep} />
              <span className="font-nexa-bold test-base md:text-md">
                {steps[activeStep]}
              </span>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div className="text-left">
            {groups[currentStep]?.questions.map((question, questionIndex) => (
              <div key={questionIndex} className="mx-4">
                <span className="font-nexa-bold xs:text-[10px] sm:text-[14px] md:text-md lg:text-lg xl:text-md 2xl:text-xl text-title-blue">
                  {question.desc_pergunta}
                </span>
                <br />

                <div className="pb-6 flex justify-center">
                  {" "}
                  {Array.from({ length: 5 }, (_, index) => (
                    <div key={index} className="flex items-center md:p-3">
                      <span className="text-gray-600 md:mx-2">{index + 1}</span>

                      <Radio
                        onChange={() =>
                          handleAnswerChange(
                            currentStep,
                            questionIndex,
                            index + 1
                          )
                        }
                        value={index + 1}
                        checked={
                          groups[currentStep]?.questions[questionIndex]
                            ?.valor_pergunta ===
                          index + 1
                        }
                        name={`radio-buttons-${question.id_pergunta}`}
                        inputProps={{ "aria-label": "A" }}
                      />
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <br />

        <div className="pb-20 flex justify-center items-center space-x-4">
          <ButtonNavbar
            color="secundary"
            onClick={handleBack}
            disabled={activeStep === 0}
            className="px-4 py-2 text-base md:text-md"
          ></ButtonNavbar>
          <ButtonNavbar
            color="primary"
            disabled={
              currentGroup === groups.length - 1 &&
              !areAllQuestionsAnswered(currentGroup)
            }
            onClick={() => {
              const currentQuestions = groups[currentGroup]?.questions;

              if (
                currentQuestions.every(
                  (question) => question.valor_pergunta !== null
                )
              ) {
                if (currentGroup < groups.length - 1) {
                  getAnswers(currentGroup);
                  setCurrentGroup(currentGroup + 1);
                  setCurrentStep(currentStep + 1);
                  setActiveStep(activeStep + 1);
                } else {
                  getAnswers(currentGroup);
                  setTimeout(() => {
                    navigate("/register/" + encodedId);
                  }, 2000);
                }
              } else {
                alert(
                  "Por favor, responda todas as perguntas antes de avançar."
                );
              }
            }}
            className={`px-4 py-2 text-base md:text-md ${currentGroup === groups.length - 1 &&
              !areAllQuestionsAnswered(currentGroup)
              ? "bg-gray-300 cursor-not-allowed"
              : "bg-primary hover:bg-primary-dark"
              }`}
          >
            {currentGroup === groups.length - 1 ? "ENVIAR" : "PRÓXIMO"}
          </ButtonNavbar>
        </div>

        <div className="fixed bottom-0 left-0 pt-4 bg-title-blue w-full h-10" />
      </Box>
    </Box>
  );
}

export default Quiz;
