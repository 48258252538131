import { Slot } from "@radix-ui/react-slot";
import classNames from "classnames";
import React from "react";

const Label = ({ size = "md", children, asChild, className }) => {
  const Comp = asChild ? Slot : "span";

  return (
    <Comp
      className={classNames(
        "text-gray-800",
        {
          "text-xs": size === "sm",
          "text-sm": size === "md",
          "text-md": size === "lg",
        },
        className
      )}
    >
      {children}
    </Comp>
  );
};

export default Label;